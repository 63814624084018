<template>
  <div class="privacy">
    <public-header :button="{url: 'login', text: $t('landing.merchant_signin')}"/>

    <div class="container">
      <h2><strong>Frequently Ask Questions (FAQ)</strong></h2>
      <ol>
        <li><span>What is LifeS and what is it used for?</span>
          <ol style="list-style-type: lower-alpha;">
            <li><span>LifeS is an online lifestyle app where users and local merchants support each other.</span></li>
            <li><span>Users can find their favorite merchants including their products and services, making purchases using the app.</span></li>
          </ol>
        </li>
      </ol>
      <p><span>&nbsp;</span></p>
      <ol start="2">
        <li><span>I am a merchant, I am interested to join LifeS, how can I get started?</span>
          <ol style="list-style-type: lower-alpha;">
            <li><span>Great, we love helping merchants get online.</span></li>
            <li><span>Please register as a new merchant by clicking on &ldquo;Join Now&rdquo; button within the &ldquo;Become a Partner&rdquo; section of the LifeS website.</span></li>
          </ol>
        </li>
      </ol>
      <p><span>&nbsp;</span></p>
      <ol start="3">
        <li><span>I am a user, I would like to purchase LifeS products and services, how do I get started?</span>
          <ol style="list-style-type: lower-alpha;">
            <li><span>Please download the LifeS app using the buttons at the top of the LifeS website.</span></li>
            <li><span>Start using your LifeS app to find your favorite products and services &amp; merchants.</span></li>
          </ol>
        </li>
      </ol>
      <p><span>&nbsp;</span></p>
      <ol start="4">
        <li><span>How can I find out more about LifeS?</span>
          <ol style="list-style-type: lower-alpha;">
            <li><span>Please explore the LifeS website,&nbsp;</span><a href="http://www.lifes.hk"><span>www.lifes.hk</span></a></li>
            <li><span>Please download the LifeS app and explore.</span></li>
            <li><span>We love to hear from you, contact LifeS via email or WhatsApp/phone.</span>
              <ol style="list-style-type: lower-roman;">
                <li><span>Email:&nbsp;</span><a href="mailto:info@lifes.hk"><span>info@lifes.hk</span></a></li>
                <li><span>WhatsApp or phone: +852 5932 8395</span></li>
              </ol>
            </li>
          </ol>
        </li>
      </ol>

    </div>
    <public-footer/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import publicHeader from '@/components/publicHeader/publicHeader'
import footer from '@/components/footer/footer'

export default {
  name: 'Privacy',
  components: {
    publicHeader,
    'public-footer': footer
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      isAuthed: 'auth/isAuthed',
      me: 'auth/me'
    })
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import "style";
</style>
